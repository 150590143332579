(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["hc"] = factory();
	else
		root["hc"] = root["hc"] || {}, root["hc"]["ads"] = factory();
})(self, function() {
return 